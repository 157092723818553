<template>
  <!--  报告查询-->
  <div class="baogao">
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <!-- 中间-->
    <van-tabs
      v-model="activeName"
      title-active-color="#00D48B "
      color="#00D48B"
      swipeable
      @change="handleTabChange"
    >
      <van-tab title="检查报告" name="a">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="sheji" v-for="(item, index) in visionList" :key="index">
            <div class="sheji1">
              <div class="sheji11">
                <img src="../../assets/img/ucenter/24.png" alt="" />
                <div style="margin-left: 10px">影像报告结果提醒</div>
              </div>
            </div>
            <div class="sheji2">
              {{ item.examdesc }}
            </div>
            <div class="sheji3">
              <div class="sheji31">
                <div style="color: #999999">{{ item.photoDate }}</div>
                <div class="sheji32" @click="Calcador(item)">详情</div>
              </div>
            </div>
          </div>
          <div class="baogao3" v-if="visionList.length == 0">
            <img src="../../assets/img/ucenter/21.png" alt="" />
          </div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="检验报告" name="b">
        <van-cell-group>
          <van-cell
            v-for="(item, index) in visionList2"
            :key="index"
            @click="gotoDetails(item)"
            :title="formatDate(item.name)"
          />
        </van-cell-group>
        <div class="baogao3" v-if="visionList2.length == 0">
          <img src="../../assets/img/ucenter/21.png" alt="" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      showPopup: false, // 弹出层
      isEmpty: false, //
      chooseItem: "",
      activeName: "a",
      activeName1: "",
      list: [],
      visionList: [],
      visionList2: [],
      page: 1,
      limit: 10,
      page2: 1,
      isLoading: false,
      isLoading2: false,
      finished: false,
      finished2: false,
      total1: 0,
      total2: 0,
      code: "",
    };
  },
  created() {
    const toast = Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: "加载中...",
    });
    this.getAliPayCode();
    this.activeName = this.$route.query.activeName;
    this.activeName1 = parseInt(this.$store.state.queryActiveName);
  },
  methods: {
    /**
     * 获取微信公众平台的code
     */
    getAliPayCode() {
      // this.$store.commit("showLoading");
      // 判断是否有微信code
      let sessionCode = sessionStorage.getItem("AliPayCode");
      if (!sessionCode) {
        // 没有则进行授权
        const redirectUrl = `${domain}index.html%23%2Fquery`;
        this.code = this.getUrlCode().code; // 截取auth_code用于获取openid
        if (this.code == null || this.code === "" || this.code == undefined) {
          // 如果没有code，则去请求
          window.location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
          );
        } else {
          sessionStorage.setItem("AliPayCode", this.code);
          // 获取openid
          this.getOpenID(this.code);
        }
      } else {
        this.getOpenID(sessionCode);
      }
    },
    /**
     * 根据code获取openid
     */
    getOpenID(code) {
      // 判断是否有openid
      if (sessionStorage.getItem("openid")) {
        this.getList();
        return;
      }
      let postData = {
        code: code,
      };
      this.$http
        .post("/api/login/wx", postData)
        .then((res) => {
          sessionStorage.setItem("user", JSON.stringify(res.data));
          sessionStorage.setItem("userid", res.data.id);
          sessionStorage.setItem("openid", res.data.userId);
          this.getList();
          this.$store.commit("hideLoading");
        })
        .catch((err) => {
          this.$store.commit("hideLoading");
          this.$toast.fail("发生错误，请关闭页面之后重新授权...");
          console.log(err);
          // 发生错误说明可能是code过期，或者session丢失，重置session重新鉴权
          sessionStorage.setItem("AliPayCode", "");
          sessionStorage.setItem("userid", "");
          sessionStorage.setItem("openid", "");
          // let redirectUrl =`${domain}index.html%23%2Fquery`;
          // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`;
        });
    },
    // 获取url中code
    getUrlCode() {
      let url = location.href;
      var s = url.indexOf("?");
      var t = url.substring(s + 1);
      let strs = t.split("&");
      let theRequest = new Object();
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return theRequest;
    },
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.showPopup = true;
        return;
      }
    },
    onLoad1() {
      if (this.visionList.length == 0) {
        this.finished = true;
        return;
      }
      this.page++;
      this.getVisionList();
    },
    // 点击跳到检验报告单详情页
    report(item) {
      window.sessionStorage.setItem("visionList2", JSON.stringify(item));
      this.$router.push({
        name: "report",
      });
    },
    // 点击跳到检查报告
    Calcador(item) {
      console.log(item);
      window.sessionStorage.setItem("visionList", JSON.stringify(item));
      this.$router.push({
        name: "calcador",
      });
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },

    chooseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.getLaboratoryList();
      this.getVisionList();
    },
    gotoDetails(item) {
      let tmpArr = [];
      for (let key in item.children) {
        tmpArr.push(...item.children[key]);
      }
      this.$store.commit("setLaboratoryDetails", tmpArr);
      this.$router.push({
        name: "report",
        query: {
          reportTime: item.name,
        },
      });
    },

    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            Toast.clear();
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            sessionStorage.setItem(
              "CurrentJZPerson",
              JSON.stringify(this.chooseItem)
            );
            this.getLaboratoryList();
            this.getVisionList();
            this.isHasPatient();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    // 检查报告查询
    getVisionList() {
      this.$toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      let dataDic = {
        clinic_id: "",
        exam_item_name: "",
        identity_id: this.chooseItem.idNumber,
        name: "",
        page: this.page,
        pageSize: this.limit,
      };
      this.visionList = [];
      this.$http
        .get("/api/record/vision", dataDic)
        .then((res) => {
          if (res.status == 200) {
            this.visionList = this.visionList.concat(res.data.result);
            this.uploadData("0101081");
            this.total1 = res.data.total;
          }

          // 数据全部加载完成
          if (this.visionList.length >= this.total1) {
            this.finished = true;
          }
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    collapseChange(activeName) {
      this.$store.commit("setQueryActiveName", activeName);
    },
    // 检验报告查询
    getLaboratoryList() {
      let postData = {
        patientId: this.chooseItem.patId,
        idCard: this.chooseItem.idNumber,
        visitCard: "",
      };
      this.visionList2 = [];
      this.$http.get("/api/record/laboratory", postData).then((res) => {
        if (res.status === 200) {
          // this.visionList2 = this.visionList2.concat(res.data.result);
          let tmp = [];
          console.log(res.data);
          for (let key in res.data) {
            tmp.push({
              name: key,
              children: res.data[key],
            });
          }
          this.visionList2 = this.visionList2.concat(tmp);
          this.uploadData("0101082");
          this.total2 = res.data.total;
        }

        // 数据全部加载完成
        if (this.visionList2.length >= this.total2) {
          this.finished2 = true;
        }
      });
    },
    //用卡信息上传
    uploadData(scene) {
      let uploadData = {
        patid: this.chooseItem.patId,
        scene: scene,
      };
      this.$healthHttpService
        .get("healthapi/api/upload", uploadData)
        .then((res) => {});
    },
    onRefresh() {
      this.page = 1;
      let dataDic = {
        clinic_id: "",
        exam_item_name: "",
        identity_id: this.chooseItem.idNumber,
        name: "",
        page: this.page,
        pageSize: this.limit,
      };
      this.$http
        .get("/api/record/vision", dataDic)
        .then((res) => {
          if (res.status == 200) {
            this.visionList = res.data.result;
            this.uploadData("0101081");
            this.total1 = res.data.total;
          }
          // 加载状态结束
          this.isLoading = false;

          // 数据全部加载完成
          if (this.visionList.length >= this.total1) {
            this.finished = true;
          }
        })
        .catch((err) => {
          Toast({ message: err.response.data });
        });
    },
    formatDate(time) {
      var date = new Date(time);

      var year = date.getFullYear(),
        month = date.getMonth() + 1, //月份是从0开始的
        day = date.getDate();
      var newTime = year + "年" + month + "月" + day + "日";
      return newTime;
    },
    handleTabChange(name, title) {
      this.$router.replace({
        query: {
          activeName: name,
        },
      });
    },
  },
};
</script>
<style scoped>
.body {
  background-color: #f8f9f9;
}

.baogao {
  width: 100%;
  height: 100%;
}

.sheji {
  width: 9rem;
  /* height: 330px; */
  padding: 0 5px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 20px;
}

.sheji1 {
  height: 50px;

  border-bottom: 1px solid #d4d4d4;
}

.sheji11 {
  display: flex;
  height: 50px;
  line-height: 50px;
  align-items: center;
}

.sheji11 img {
  width: 20px;
  height: 20px;
}

.sheji2 {
  /* height:230px; */
  line-height: 25px;
  text-align: left;
  color: #666666;
  border-bottom: 1px solid #d4d4d4;
}

.sheji3 {
  height: 50px;
}

.sheji31 {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sheji32 {
  width: 70px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border: 1px solid #00d58b;
  color: #00d58b;
}

.baogao1 {
  width: 10rem;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  /* margin-top: 20px; */
  font-size: 16px;
}

.baogao2 {
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}
.baogao-detail {
  color: #00d58b;
  padding-bottom: 0.2rem;
}
.group-month {
  text-align: left;
  color: #333333;
  font-weight: bold;
}
.group-item {
  box-shadow: #e6e6e6 0 0 10px;
  border-radius: 5px;
  margin: 10px 0;
}
/deep/ .van-cell__title {
  text-align: left;
}
/deep/ .van-tab--active {
  background-color: #f5f5f5;
}
</style>
